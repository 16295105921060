import { IconBox } from 'components/common/Icons/Icons'
import { LogoService } from 'services/Utils/logo'
import { getNormalize } from 'services/Utils/parseString.utils'

import { FieldTypeEnum } from 'models/metadata.model'
import type { BreadcrumbInfo, CreateActionFields, Item, SelectOptions } from 'models/motion/motionBuilder.model'
import { InputOperatorEnum } from 'models/motion/motionBuilder.model'

const getMenuItemIcon = (item: Partial<BreadcrumbInfo>, includeObjectIcon?: boolean): JSX.Element | null => {
  let icon = null
  switch (item.entityType) {
    case 'platform':
      if (item?.name && !item.key) {
        // TODO: Remove the replace of snowflakedb once isn't needed anymore (MAGPROD-3416) - or we entirely migrate to Airbyte
        icon = (
          <img
            key={item.name?.replace('snowflakedb', 'snowflake')}
            src={LogoService.getIcon(getNormalize(item.name))}
            title={item.name?.replace('snowflakedb', 'snowflake')}
            alt={item.name?.replace('snowflakedb', 'snowflake')}
            width='24'
            height='24'
          />
        )
      }
      break
    case 'object':
      icon = includeObjectIcon ? <IconBox /> : null
      break
    default:
      break
  }
  return icon
}

const getElementSelectOptions = (type: string | undefined) => {
  let options: SelectOptions[] = []
  const nullishOperators = [
    { label: 'Is null', value: InputOperatorEnum.IsNull },
    { label: 'Is not null', value: InputOperatorEnum.IsNotNull },
  ]

  const multiValueOperators = [
    { label: 'Is one of', value: InputOperatorEnum.AnyOf },
    { label: 'Is not one of', value: InputOperatorEnum.NoneOf },
    { label: 'Contains one of', value: InputOperatorEnum.ContainsOneOf },
    { label: 'Not contains any of', value: InputOperatorEnum.NotContainsAnyOf },
  ]
  switch (type) {
    case FieldTypeEnum.Int:
    case FieldTypeEnum.Integer:
    case FieldTypeEnum.Number:
    case FieldTypeEnum.Currency:
    case FieldTypeEnum.Double:
    case FieldTypeEnum.Decimal:
    case FieldTypeEnum.Percent:
      options = [
        { label: '< Less than', value: InputOperatorEnum.LessThan },
        { label: '<= Equals or less than', value: InputOperatorEnum.EqualsOrLessThan },
        { label: '= Equals', value: InputOperatorEnum.Equals },
        { label: '>= Equals or greater than', value: InputOperatorEnum.EqualsOrGreaterThan },
        { label: '> Greater than', value: InputOperatorEnum.GreaterThan },
        { label: '<> Between', value: InputOperatorEnum.Between },
        ...multiValueOperators,
        ...nullishOperators,
      ]
      break
    case FieldTypeEnum.Url:
    case FieldTypeEnum.Id:
    case FieldTypeEnum.Textarea:
    case FieldTypeEnum.Address:
    case FieldTypeEnum.String:
    case FieldTypeEnum.Phone:
    case FieldTypeEnum.Reference:
    case FieldTypeEnum.Regexp:
    case FieldTypeEnum.Text:
    case FieldTypeEnum.Email:
      options = [
        { label: 'Contains', value: InputOperatorEnum.Contains },
        { label: 'Equals', value: InputOperatorEnum.Equals },
        { label: 'Not contains', value: InputOperatorEnum.NotContains },
        ...multiValueOperators,
        ...nullishOperators,
      ]
      break
    case 'range':
      options = [{ label: 'Between', value: InputOperatorEnum.Between }]
      break
    case FieldTypeEnum.Picklist:
    case FieldTypeEnum.Collection:
    case FieldTypeEnum.Dropdown:
    case FieldTypeEnum.Select:
      options = [
        { label: 'Include', value: InputOperatorEnum.Include },
        { label: 'Not include', value: InputOperatorEnum.NotInclude },
      ]
      break
    case FieldTypeEnum.Date:
    case FieldTypeEnum.Datetime:
    case FieldTypeEnum.Datepicker:
      options = [
        { label: 'Is on', value: InputOperatorEnum.Equals },
        { label: 'Is before/on', value: InputOperatorEnum.EqualsOrLessThan },
        { label: 'Is after/on', value: InputOperatorEnum.EqualsOrGreaterThan },
        { label: 'Between', value: InputOperatorEnum.Between },
        { label: 'Within the next # of days', value: InputOperatorEnum.WithinNext },
        { label: 'Within the last # of days', value: InputOperatorEnum.WithinLast },
        ...nullishOperators,
      ]
      break
    case FieldTypeEnum.Boolean:
      options = [
        { label: 'Equal to', value: InputOperatorEnum.Equals },
        { label: 'Not equal to', value: InputOperatorEnum.NotEquals },
      ]
      break
    default:
      options = [{ label: `${type}: Not found `, value: InputOperatorEnum.NotFound }]
      break
  }

  return options
}

const isDynamicInputExceptionField = (field: Item | CreateActionFields) => {
  if (!field) return false

  const exceptionFields = [
    {
      platform: 'slack',
      object: 'message',
      key: 'from',
    },
    {
      platform: 'slack',
      object: 'channel',
      key: 'to',
    },
    {
      platform: 'slack',
      object: 'channel',
      key: 'from',
    },
  ]

  return exceptionFields.some(
    ({ key, object, platform }) => platform === field.platform && object === field.object && key === field.key,
  )
}

const allowDynamicInput = (field: Item | CreateActionFields) => {
  const isExceptionField = isDynamicInputExceptionField(field)

  if (['collection', 'picklist', 'dropdown', 'select'].includes(field?.type || '') && !isExceptionField) {
    return false
  }
  const staticFields = [
    {
      platform: 'marketo',
      object: 'sendemail',
      key: 'campaign_id',
    },
    // SendGrid email templates, slack messages and post to channel dynamic input values are handled externally.
    {
      platform: 'magnify',
      object: 'email',
      key: 'template',
    },
    {
      platform: 'magnify',
      object: 'email',
      key: 'senderemail',
    },
    {
      platform: 'slack',
      object: 'message',
      key: 'messagecontent',
    },
    {
      platform: 'slack',
      object: 'channel',
      key: 'messagecontent',
    },
  ]
  const isStaticField = staticFields.some(
    ({ key, object, platform }) =>
      platform === (field.platform?.toLowerCase() || '') &&
      object === (field.object?.toLowerCase() || '') &&
      key === (field.key?.toLowerCase() ?? ''),
  )

  return !isStaticField
}

export { allowDynamicInput, getMenuItemIcon, getElementSelectOptions, isDynamicInputExceptionField }
