import { Form, Layout, message, notification, Spin } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { useDeepCompareEffect } from 'react-use'

import { DeleteNodeModal } from 'components/common'
import MotionAlertModal from 'components/MotionAlertModal'
import { MotionSidebar } from 'components/MotionBuilder/MotionSidebar/MotionSidebar'
import MotionExecutionModal from 'components/MotionExecutionModal'
import { MotionTarget } from 'components/MotionTarget/MotionTarget'
import MotionTopBar from 'components/MotionTopBar/MotionTopBar'
import useABExperiment from 'hooks/useABExperiment'
import { useCallbackPrompt } from 'hooks/useCallbackPrompt'
import useCheckMotionDifferences from 'hooks/useCheckMotionDifferences'
import useDemoFeature from 'hooks/useDemoFeature'
import useMotionElements from 'hooks/useMotionElements'
import ExperimentModal from 'pages/Motions/ExperimentModal'
import { determineWhetherSaveIsEnabled, successMotionMessage } from 'pages/Motions/Motion.utils'
import { reactFlowToDSL } from 'services/Utils/dslConversion/reactFlowToDSL/reactFlowToDSL.utils'
import { initCurrentMotion } from 'store/motion.store'
import { SegmentBuilderProvider } from 'store/SegmentBuilderContext'
import useStore from 'store/useStore'

import type { Params } from 'react-router-dom'

import type { SegmentBuilderData } from 'models/motion/motionBuilder.model'
import type { Motion } from 'models/motion.model'
import { MotionStateEnum, MotionStatusEnum } from 'models/motion.model'

interface LocationState {
  to?: string
}

export const MotionPage = observer(() => {
  const { isMockApiEnabled } = useDemoFeature()

  const { aggregationsDataStore, motionStore, motionGoalsStore, metadataStore, observabilityStore } = useStore()
  const { motionSegmentTotals, isInMotionReportingEnabled } = motionStore

  const [isEditingMotionTitle, setIsEditingMotionTitle] = useState<boolean>(false)
  const [segmentBuilderData, setSegmentBuilderData] = useState<SegmentBuilderData>({} as SegmentBuilderData)
  const [form] = Form.useForm()

  const { id, version }: Readonly<Params<string>> = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!id && !version && location.pathname.includes('new')) {
      motionStore.initCurrentMotion(initCurrentMotion || undefined)
    }

    motionStore.setConfigPanelNode(null)
    motionStore.setFocusedNode(null)
  }, [id, version, location])

  useEffect(() => {
    if (id && version) {
      motionStore.get({ playbookId: id, version: Number(version) }).catch(console.error)

      if (motionStore.doesContainExperiment()) {
        initExperimentSegmentSizes(motionStore.currentMotion?.experiment?.segmentSizes)
      }
    }

    return () => {
      notification.destroy()
      // Reset the current Motion to prevent the user from seeing the previous Motion
      motionStore.initCurrentMotion()
    }
  }, [])

  useEffect(() => {
    // Enable isInMotionReportingEnabled if the motion is not in the draft state
    if (motionStore.currentMotion) {
      const isMotionStateDraft = motionStore.currentMotion?.currState === MotionStateEnum.Draft
      motionStore.setIsInMotionReportingEnabled(!isMotionStateDraft)
      if (!isMotionStateDraft && id) {
        observabilityStore.fetchTenantInMotionReportingTotals(id).catch(console.error)
      }
    }
    return () => {
      motionStore.setIsInMotionReportingEnabled(false)
    }
  }, [motionStore.currentMotion])

  const onShowDrawer = (data: SegmentBuilderData) => {
    metadataStore
      .getMetadataActionFields({
        platform: data.platform,
        object: data.object,
        action: data.action,
        solutionInstanceId: data.solutionInstanceId,
      })
      .catch(console.error)
    setSegmentBuilderData(data)
    motionStore.setConfigPanelNode(data.nodeId)
  }

  const {
    showExperimentModal,
    setShowExperimentModal,
    isABExperimentModeEnabled,
    setIsABExperimentModeEnabled,
    isEditingVariantA,
    isEditingVariantB,
    isABSplitScreenEnabled,
    isEditingExperiment,
    editExperimentVariant,
    disableEditingExperiment,
    selectedExperimentSegementSizes,
    handleExperimentSegementSizeChange,
    initExperimentSegmentSizes,
    getSelectedSegmentSizeTotal,
  } = useABExperiment()

  const {
    elements,
    setElements,
    experimentElements,
    setExperimentElements,
    setElementsWithOptimizedPositions,
    updateElementsUsingDsl,
    reset,
    setReset,
  } = useMotionElements(onShowDrawer)

  const {
    hasOverallDSLChanged,
    hasSegmentDSLChanged,
    hasOperationAndActionDSLChanged,
    hasExperimentDSLChanged,
    hasTitleOrDescriptionChanged,
    hasGoalsChanged,
    hasMetricsChanged,
  } = useCheckMotionDifferences(elements, experimentElements)

  const closeRedirectPath = (location.state as LocationState)?.to ?? `/motions`

  const redirectToUpdateMotion = useCallback(
    (id: string, version: number) => {
      return navigate(`/motions/motion/${id}/${version}`, { state: { to: closeRedirectPath } })
    },
    [navigate],
  )

  const updateUrl = ({ playbookId, version }: Motion) => {
    confirmNavigation()
    redirectToUpdateMotion(playbookId, version)
  }

  const [showSaveModal, setShowSaveModal] = useState<boolean>(false)
  const [showEditSegmentModal, setShowEditSegmentModal] = useState<boolean>(false)

  const isMultiRunMotion = useMemo(() => !!motionStore.currentMotion?.schedule, [motionStore.currentMotion])

  const isSaveDisabled = !determineWhetherSaveIsEnabled(
    motionStore.currentMotion,
    hasTitleOrDescriptionChanged,
    hasOverallDSLChanged,
    hasSegmentDSLChanged,
    hasOperationAndActionDSLChanged,
    hasExperimentDSLChanged,
    isMultiRunMotion,
    isABExperimentModeEnabled,
    hasGoalsChanged,
    hasMetricsChanged,
    isMockApiEnabled,
  )

  useDeepCompareEffect(() => {
    if (
      (hasTitleOrDescriptionChanged || hasGoalsChanged || hasMetricsChanged || hasOverallDSLChanged) &&
      !isInMotionReportingEnabled
    ) {
      setShowSaveModal(true)
    } else {
      setShowSaveModal(false)
    }
  }, [elements, motionGoalsStore.currentMotionGoals, motionStore.currentMotion, isInMotionReportingEnabled])

  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(showSaveModal)

  const motionHasTitle = () => {
    if (!motionGoalsStore.currentMotionGoals.title) {
      cancelNavigation()
      setIsEditingMotionTitle(true)
      form.validateFields(['title']).catch(console.error)

      return false
    }

    return true
  }

  const handleOnSave = async ({
    updateMotionUrl,
    confirmCloseModal = false,
  }: {
    updateMotionUrl: boolean
    confirmCloseModal: boolean
  }) => {
    if (!motionHasTitle() || isEditingMotionTitle) return

    const isMotionScheduledOrExecuting =
      motionStore.currentMotion?.currState === MotionStateEnum.Scheduled ||
      motionStore.currentMotion?.currState === MotionStateEnum.Executing

    // Explicitly handle if it's the edit segment scenario
    if (isMotionScheduledOrExecuting && isMultiRunMotion && hasSegmentDSLChanged && !hasOperationAndActionDSLChanged) {
      setShowEditSegmentModal(true)
    } else {
      await onSaveData({ updateMotionUrl, confirmCloseModal })
    }
  }

  const onSaveData = async ({
    updateMotionUrl,
    confirmCloseModal = false,
  }: {
    updateMotionUrl: boolean
    confirmCloseModal: boolean
  }) => {
    if (!motionHasTitle() || isEditingMotionTitle) return
    if (showEditSegmentModal) setShowEditSegmentModal(false)

    const dsl = reactFlowToDSL({
      elements,
      aggregations: aggregationsDataStore.aggregationsList,
    })
    const motion = {
      ...motionStore.currentMotion,
      ...motionGoalsStore.currentMotionGoals,
      dsl,
    } as Motion

    if (!id) {
      const response = await motionStore.post(motion)
      if (typeof response === 'undefined' || response instanceof Error) {
        message.error(response?.message ?? 'There was an error saving your Motion', 4)
        return
      } else if (response) {
        if (updateMotionUrl) {
          updateUrl(response)
        }
        message.success(successMotionMessage(MotionStatusEnum.Added))
      }
    } else {
      const isMotionScheduledOrExecuting =
        motionStore.currentMotion?.currState === MotionStateEnum.Scheduled ||
        motionStore.currentMotion?.currState === MotionStateEnum.Executing

      const isEditSegmentWhilstExecuting =
        isMotionScheduledOrExecuting && isMultiRunMotion && hasSegmentDSLChanged && !hasOperationAndActionDSLChanged

      const response = await motionStore.update(motion, isEditSegmentWhilstExecuting)
      if (typeof response === 'undefined' || response instanceof Error) {
        message.error(response?.message ?? 'There was an error saving your Motion', 4)
        return
      } else if (response) {
        if (updateMotionUrl) {
          updateUrl(response)
        }
        message.success(successMotionMessage(MotionStatusEnum.Updated))
      }
    }

    if (confirmCloseModal) confirmNavigation()
    setShowSaveModal(false)

    // Refresh the Motions to prevent the user from having to manually refresh the entire application
    await motionStore.getAll(true)
  }

  const initiateExperimentMode = () => {
    setIsABExperimentModeEnabled(true)
    if (motionStore.doesContainExperiment()) {
      updateElementsUsingDsl(motionStore.currentMotion?.experiment?.dsl, true)
    } else {
      setExperimentElements(elements)
    }

    setShowExperimentModal(false)
  }

  // For demo purposes, we are saving the experiment to the store and not the api
  const temporySaveExperiment = () => {
    motionStore.tempUpdate()
    motionStore.setExperiment({
      id: '',
      dsl: reactFlowToDSL({
        elements: experimentElements,
        // aggregations: aggregationsDataStore.experimentAggregationsList, // TODO: Add aggregations to experiment
      }),
      segmentSizes: selectedExperimentSegementSizes,
    })
    setTimeout(() => {
      message.success('Successfully created and saved experiment')
    }, 1400)
  }

  /** Functions we need throughout the Motion page that are difficult to pass around. */
  const store = {
    elements,
    setElements,
    setElementsWithOptimizedPositions,
    segmentBuilderData,
    setSegmentBuilderData,
    experimentElements,
    setExperimentElements,
    isABSplitScreenEnabled,
    isABExperimentModeEnabled,
    isEditingVariantA,
    isEditingVariantB,
    selectedExperimentSegementSizes,
    setShowExperimentModal,
    editExperimentVariant,
    reset,
    setReset,
  }

  return (
    <SegmentBuilderProvider store={store}>
      <Layout className='ant-layout-has-sider motion-builder'>
        {/* Discard/Save Motion modal */}
        <MotionAlertModal
          isModalOpen={showPrompt}
          handleCancel={confirmNavigation}
          handleClose={cancelNavigation}
          handleSubmit={() => onSaveData({ updateMotionUrl: false, confirmCloseModal: true })}
          cancelButtonText='Discard changes'
          submitButtonText='Save'
          modalHeadingText='Save Motion?'
          modalBodyText='You have unsaved changes to your Motion. Do you want to save the Motion as you click away?'
          isSubmitButtonDisabled={isSaveDisabled}
        />

        {/* Edit Segment whilst executing modal */}
        <MotionAlertModal
          isModalOpen={showEditSegmentModal}
          handleCancel={() => setShowEditSegmentModal(false)}
          handleClose={() => setShowEditSegmentModal(false)}
          handleSubmit={() => onSaveData({ updateMotionUrl: true, confirmCloseModal: false })}
          cancelButtonText='Cancel'
          submitButtonText='Continue'
          modalHeadingText='Save and re-run Motion?'
          modalBodyText='You have modified the Motion segment whilst it is currently running. If you choose to save, the Motion will
        recalculate the segment accounts and users, and begin running from the next scheduled execution.'
        />
        <MotionExecutionModal />
        <DeleteNodeModal setElements={setElementsWithOptimizedPositions} setReset={setReset} />
        {isMockApiEnabled && (
          <ExperimentModal
            showDialog={showExperimentModal}
            onCancel={() => {
              setShowExperimentModal(false)
            }}
            onConfirm={() => {
              initiateExperimentMode()
            }}
            totalAccounts={isMockApiEnabled ? 100 : motionSegmentTotals?.totalAccount}
            selectedExperimentSegementSizes={selectedExperimentSegementSizes}
            handleExperimentSegementSizeChange={handleExperimentSegementSizeChange}
            doesExperimentExist={motionStore.doesContainExperiment()}
            getSelectedSegmentSizeTotal={getSelectedSegmentSizeTotal}
          />
        )}

        {!isABSplitScreenEnabled() && !isInMotionReportingEnabled && <MotionSidebar />}

        <Layout className='canvas-content canvas-builder'>
          <Content>
            <Spin size='large' spinning={motionStore.isMotionLoading}>
              <MotionTopBar
                isEditingMotionTitle={isEditingMotionTitle}
                form={form}
                setIsEditingMotionTitle={setIsEditingMotionTitle}
                isABExperimentModeEnabled={isABExperimentModeEnabled}
                isEditingExperiment={isEditingExperiment}
                initiateExperimentMode={initiateExperimentMode}
                setShowExperimentModal={setShowExperimentModal}
                setIsABExperimentModeEnabled={setIsABExperimentModeEnabled}
                temporySaveExperiment={temporySaveExperiment}
                handleOnSave={handleOnSave}
                isSaveDisabled={isSaveDisabled}
                disableEditingExperiment={disableEditingExperiment}
                closeRedirectPath={closeRedirectPath}
              />

              <MotionTarget
                onShowDrawer={onShowDrawer}
                totalAccounts={isMockApiEnabled ? 100 : (motionSegmentTotals?.totalAccount ?? 0)}
              />
            </Spin>
          </Content>
        </Layout>
      </Layout>
    </SegmentBuilderProvider>
  )
})
MotionPage.displayName = 'MotionPage'
