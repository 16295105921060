import { Input } from 'antd'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

import { IconSearch } from 'components/common/Icons/Icons'

import type { InputRef } from 'antd'
import type { Dispatch, SetStateAction } from 'react'

type SearchVariants = 'onType' | 'onEnter' | 'onClick'
interface SearchProps {
  prefix?: JSX.Element
  placeholder?: string
  onFilter: (inputValue: string) => void
  searchType?: SearchVariants[]
  setDisplay?: (value: boolean) => void
  searchInput: string
  setSearchInput: Dispatch<SetStateAction<string>> | ((value: string) => void)
  onClose: () => void
  large?: boolean
  isDisabled?: boolean
  dataTestId?: string
}

export const Search = ({
  prefix,
  placeholder,
  onFilter,
  searchType = ['onEnter'],
  setDisplay = () => {},
  searchInput = '',
  setSearchInput,
  onClose,
  large = false,
  isDisabled = false,
  dataTestId = 'search',
}: SearchProps) => {
  const icon = prefix ?? <IconSearch />
  const searchRef = useRef<InputRef>(null)

  const [displayInitial, setDisplayInitial] = useState<boolean>(true)

  useEffect(() => {
    if (searchInput.length) {
      setDisplayInitial(false)
    } else {
      setDisplayInitial(true)
    }
  }, [searchInput])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value)
    if (searchType.includes('onType')) {
      onFilter(event.target.value)
    }
    if (!event.target.value.length) {
      setDisplay(false)
      onClose()
    }
  }

  const handlePressEnter = (event: React.KeyboardEvent) => {
    if (searchType.includes('onEnter')) {
      onFilter(searchInput)
    }
    searchRef?.current?.blur()
    event.preventDefault()
  }

  const handleOnSearch = () => {
    if (searchType.includes('onClick')) {
      onFilter(searchInput)
    }
  }

  return (
    <Input.Search
      placeholder={placeholder ?? 'search'}
      prefix={(displayInitial || searchType.includes('onType')) && <span className='btn__search--init'>{icon}</span>}
      value={searchInput}
      onChange={handleChange}
      onPressEnter={handlePressEnter}
      data-testid={dataTestId}
      className={classNames({
        search: true,
        large,
      })}
      allowClear
      disabled={isDisabled}
      ref={searchRef}
      suffix={
        !displayInitial &&
        !searchType.includes('onType') && (
          // filtering on type doesn't require any button to search
          <span className='btn__search' data-testid='btnSearch' onMouseDown={handleOnSearch}>
            <IconSearch />
          </span>
        )
      }
    />
  )
}
